import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0016 21.0041H7.9983C5.23615 21.0041 2.99622 18.7641 2.99622 16.002V7.99867C2.99622 5.23651 5.23615 2.99658 7.9983 2.99658H16.0016C18.7638 2.99658 21.0037 5.23651 21.0037 7.99867V16.002C21.0037 18.7641 18.7638 21.0041 16.0016 21.0041Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 7.99864C11.8609 7.99864 11.7489 8.11069 11.7499 8.24874C11.7499 8.3868 11.8619 8.49885 12 8.49885C12.138 8.49885 12.2501 8.3868 12.2501 8.24874C12.2501 8.11069 12.138 7.99864 11.999 7.99864"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2501 16.1861V11.4341H11.2496"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
