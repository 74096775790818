import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 88.08 79.53"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="transparent"
    {...props}
  >
    <defs>
     
      <style type="text/css" />
      <mask id="id0">
        <linearGradient
          id="id1"
          gradientUnits="userSpaceOnUse"
          x1={74.69}
          y1={2.64}
          x2={44.61}
          y2={9.41}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: "white",
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: "white",
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: "url(#id1)",
          }}
          x={41.44}
          width={43.36}
          height={39.76}
        />
      </mask>
      <mask id="id2">
        <linearGradient
          id="id3"
          gradientUnits="userSpaceOnUse"
          x1={74.69}
          y1={2.64}
          x2={44.61}
          y2={9.41}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 1,
              stopColor: "white",
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 0,
              stopColor: "white",
            }}
          />
        </linearGradient>
        <rect
          style={{
            fill: "url(#id3)",
          }}
          y={6.42}
          width={41.44}
          height={73.11}
        />
      </mask>
    </defs>
    <g id="Plan_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_1384382714016">
        <text x={82.99} y={4.66} className="fil0 fnt0">
          {"\xD2"}
        </text>
        <g id="_1823664167760">
          <path
            className="fil1"
            d="M63.68 0c11.97,0 21.12,8.05 21.12,20.02 0,11.97 -11.92,19.74 -23.89,19.74 -11.97,0 -19.47,-7.77 -19.47,-19.74 0,-11.97 10.25,-20.02 22.24,-20.02l0 0zm12.77 7.32c-2.37,0.58 -6.16,1.7 -6.67,2.8 -0.76,1.61 -3.43,2.86 -3.43,2.86 0,0 2,0.48 0.84,1.72 -1.14,1.23 2.31,2.87 -0.84,2.2 -3.16,-0.67 -1.25,2.28 0.09,2.95 1.33,0.67 3.62,0 3.92,-0.95 0.28,-0.97 1.53,-4.52 6.56,-1.7 1.6,0.88 0.37,1.66 0.22,2.26 -0.32,1.29 -3.58,1.83 -5.15,1.32 -2.28,-0.76 -6.74,3.83 -2.37,7.16 4.4,3.34 6.57,-0.1 5.24,4.57 -0.24,0.75 3.44,-1.03 5.16,-3.85 0.89,-2.37 1.36,-4.91 1.36,-7.58 0,-5.23 -1.85,-10.01 -4.93,-13.76l0 0zm-18.45 -1.92c0,0 0.04,4.44 0.71,5.67 0.67,1.24 0.47,4.86 -2.2,6.11 -2.67,1.23 -2.67,1.89 -1.33,3.14 0.9,0.84 0.9,2.67 -0.47,2.61 -0.67,-0.02 -2.78,-1.55 -3.15,-1.85 -1.14,-0.84 -3.44,2.49 -0.95,3.72 2.48,1.25 6.08,-0.6 6.66,4.24 0.58,4.87 0.38,6.12 0.54,7.45 0.15,1.34 -4.14,-0.26 -6.57,-1.79 -3.64,-3.89 -5.9,-9.1 -5.9,-14.87 0,-5.21 1.85,-9.99 4.91,-13.74l7.75 -0.69 0 0z"
          />
          <path
            className="fil1"
            d="M25.68 31.82c-0.96,-2.26 3.71,-0.62 5.84,-3.19l2.97 -5.9c-1.62,-7.47 3.34,-7.57 -0.71,-10.98 -12.19,-10.22 -19.64,-4.15 -23.1,4.83 -0.5,1.29 -0.11,3.01 0.15,4.24 0.06,0.43 -1.98,2.07 -2.67,5.08 -2.13,8.96 3.51,13.15 4.97,10.51 1.27,-2.33 -1.31,-12.84 -0.99,-12.43 1.98,2.5 5.43,6.55 6.12,6.83 3.33,1.35 0.06,4.95 0.13,4.84l-7.88 11.58 -7.84 8.98 4.67 2.61 -7.34 15.67 7.51 3.6 1.04 -1.81 -2.33 -3.49 7.52 -9.82 3.85 0.5 -3.04 16.06 9.18 -0.02 -0.22 -1.66 -3.34 -1.96 4.2 -11.02 6.95 -0.74 -1.55 -18.66 11.67 -13.13c-1.65,-1.92 -0.15,-0.56 -2.19,-2.03 -2.41,1.9 -4.8,3.79 -7.19,5.69l-1.19 0.56 -2.67 -0.2c0.05,0.02 -1.55,-2.26 -2.52,-4.54l0 0z"
          />
        </g>
        <g id="_1823664167760_0">
          <path
            className="fil2"
            style={{
              mask: "url(#id0)",
            }}
            d="M63.68 0c11.97,0 21.12,8.05 21.12,20.02 0,11.97 -11.92,19.74 -23.89,19.74 -11.97,0 -19.47,-7.77 -19.47,-19.74 0,-11.97 10.25,-20.02 22.24,-20.02l0 0zm12.77 7.32c-2.37,0.58 -6.16,1.7 -6.67,2.8 -0.76,1.61 -3.43,2.86 -3.43,2.86 0,0 2,0.48 0.84,1.72 -1.14,1.23 2.31,2.87 -0.84,2.2 -3.16,-0.67 -1.25,2.28 0.09,2.95 1.33,0.67 3.62,0 3.92,-0.95 0.28,-0.97 1.53,-4.52 6.56,-1.7 1.6,0.88 0.37,1.66 0.22,2.26 -0.32,1.29 -3.58,1.83 -5.15,1.32 -2.28,-0.76 -6.74,3.83 -2.37,7.16 4.4,3.34 6.57,-0.1 5.24,4.57 -0.24,0.75 3.44,-1.03 5.16,-3.85 0.89,-2.37 1.36,-4.91 1.36,-7.58 0,-5.23 -1.85,-10.01 -4.93,-13.76l0 0zm-18.45 -1.92c0,0 0.04,4.44 0.71,5.67 0.67,1.24 0.47,4.86 -2.2,6.11 -2.67,1.23 -2.67,1.89 -1.33,3.14 0.9,0.84 0.9,2.67 -0.47,2.61 -0.67,-0.02 -2.78,-1.55 -3.15,-1.85 -1.14,-0.84 -3.44,2.49 -0.95,3.72 2.48,1.25 6.08,-0.6 6.66,4.24 0.58,4.87 0.38,6.12 0.54,7.45 0.15,1.34 -4.14,-0.26 -6.57,-1.79 -3.64,-3.89 -5.9,-9.1 -5.9,-14.87 0,-5.21 1.85,-9.99 4.91,-13.74l7.75 -0.69 0 0z"
          />
          <path
            className="fil2"
            style={{
              mask: "url(#id2)",
            }}
            d="M25.68 31.82c-0.96,-2.26 3.71,-0.62 5.84,-3.19l2.97 -5.9c-1.62,-7.47 3.34,-7.57 -0.71,-10.98 -12.19,-10.22 -19.64,-4.15 -23.1,4.83 -0.5,1.29 -0.11,3.01 0.15,4.24 0.06,0.43 -1.98,2.07 -2.67,5.08 -2.13,8.96 3.51,13.15 4.97,10.51 1.27,-2.33 -1.31,-12.84 -0.99,-12.43 1.98,2.5 5.43,6.55 6.12,6.83 3.33,1.35 0.06,4.95 0.13,4.84l-7.88 11.58 -7.84 8.98 4.67 2.61 -7.34 15.67 7.51 3.6 1.04 -1.81 -2.33 -3.49 7.52 -9.82 3.85 0.5 -3.04 16.06 9.18 -0.02 -0.22 -1.66 -3.34 -1.96 4.2 -11.02 6.95 -0.74 -1.55 -18.66 11.67 -13.13c-1.65,-1.92 -0.15,-0.56 -2.19,-2.03 -2.41,1.9 -4.8,3.79 -7.19,5.69l-1.19 0.56 -2.67 -0.2c0.05,0.02 -1.55,-2.26 -2.52,-4.54l0 0z"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default SvgComponent
