import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0013 12.0001V7.99846C15.0013 7.44595 14.5534 6.99805 14.0009 6.99805H9.9992C9.44668 6.99805 8.99878 7.44595 8.99878 7.99846V9.9993"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0008 17.0023V13.0007C14.0008 12.4481 14.4487 12.0002 15.0012 12.0002H17.002C17.5546 12.0002 18.0025 12.4481 18.0025 13.0007V16.0019C18.0025 16.5544 17.5546 17.0023 17.002 17.0023H6.99787C6.44535 17.0023 5.99744 16.5544 5.99731 16.0019V12.4838C5.99731 12.0145 6.26245 11.5855 6.68218 11.3757L9.16984 10.1318C9.55287 9.94033 10.0076 9.96008 10.3726 10.1841C10.7376 10.4081 10.9611 10.8046 10.9638 11.2328L10.9997 17.0023"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6.9977V5.49707"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={2.99622}
      y={2.99609}
      width={18.0075}
      height={18.0075}
      rx={5}
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
)
export default SvgComponent
