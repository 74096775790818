import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { routeType } from "../types";

type States = {
  breadcrumb : routeType[],
  winSize: {w:number, h:number},
  detailsScrollOn: boolean
  user: null | {name:string, id:string, email:string, photo?:string}
}
type Functions = {
  
 setBreadcrumb: (data:routeType[]) => void,
 setSize: (data:{w:number, h:number}) => void,
 setdetailsScrollOn: (data:boolean) => void,
 setUser: (data: {name:string, id:string, email:string, photo?:string} | null) => void

}
type Props = States & Functions
const AppContext = createContext<Props>({} as Props);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    breadcrumb: [
        {
            name:'Home',
            path: '/'
        }
    ],
    winSize: {w:0, h:0},
    detailsScrollOn: false,
    user:null
   
  })
  

  const value = useMemo(() => {
  

  
    const setBreadcrumb = (data:routeType[]) => {
      setState((prev) => ({
        ...prev,
       breadcrumb: data
      }))
    }
    const setSize = (data:{w:number, h:number}) => {
      setState((prev) => ({
        ...prev,
        winSize: data
      }))
    }
    const setdetailsScrollOn = (data:boolean) => {
      setState((prev) => ({
        ...prev,
        detailsScrollOn: data
      }))
    }
    const setUser= (data: {name:string, id:string, email:string, photo?:string} | null) => {
      setState((prev) => ({
        ...prev,
        user: data
      }))
    }
    return {
      ...state,
      setBreadcrumb,
      setSize,
      setdetailsScrollOn,
      setUser
    }
  }, [state])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};