import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_735_1218)">
      <path
        d="M11.998 19.5C11.722 19.5 11.498 19.724 11.5 20C11.5 20.276 11.724 20.5 12 20.5C12.276 20.5 12.5 20.276 12.5 20C12.5 19.724 12.276 19.5 11.998 19.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0884 19.9116C16.1372 19.9604 16.1372 20.0396 16.0884 20.0884C16.0396 20.1372 15.9604 20.1372 15.9116 20.0884C15.8628 20.0396 15.8628 19.9604 15.9116 19.9116C15.9604 19.8628 16.0396 19.8628 16.0884 19.9116Z"
        fill="currentColor"
      />
      <path
        d="M16.0884 19.9116C16.1372 19.9604 16.1372 20.0396 16.0884 20.0884C16.0396 20.1372 15.9604 20.1372 15.9116 20.0884C15.8628 20.0396 15.8628 19.9604 15.9116 19.9116C15.9604 19.8628 16.0396 19.8628 16.0884 19.9116Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.08839 19.9116C8.1372 19.9604 8.1372 20.0396 8.08839 20.0884C8.03957 20.1372 7.96043 20.1372 7.91161 20.0884C7.8628 20.0396 7.8628 19.9604 7.91161 19.9116C7.96043 19.8628 8.03957 19.8628 8.08839 19.9116Z"
        fill="currentColor"
      />
      <path
        d="M8.08839 19.9116C8.1372 19.9604 8.1372 20.0396 8.08839 20.0884C8.03957 20.1372 7.96043 20.1372 7.91161 20.0884C7.8628 20.0396 7.8628 19.9604 7.91161 19.9116C7.96043 19.8628 8.03957 19.8628 8.08839 19.9116Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.918 16H5.082C3.932 16 3 15.068 3 13.918V5.082C3 3.932 3.932 3 5.082 3H18.919C20.068 3 21 3.932 21 5.082V13.919C21 15.068 20.068 16 18.918 16Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_735_1218">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
