import React from 'react'
import Icon from '../../icons/Icon'

export default function PageInner({children} : {children:JSX.Element}) {
  return (
    <div className="relative w-full overflow-hidden">
      
        <div className='  relative w-full h-full'>
            {children}
        </div>
    </div>
  )
}
