import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0025 9.99917V7.82626C18.0025 7.29565 17.7915 6.78681 17.4162 6.41167L14.5871 3.58249C14.2119 3.20724 13.7031 2.99625 13.1725 2.99625H5.99749C4.89246 2.99625 3.99666 3.89205 3.99666 4.99708V19.0029C3.99666 20.1079 4.89246 21.0037 5.99749 21.0037H9.99916"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0025 7.99833H14.0008C13.4483 7.99833 13.0004 7.55043 13.0004 6.99792V2.99625"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={13.0004}
      y={17.2522}
      width={3.00125}
      height={3.75156}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={16.0017}
      y={13.0004}
      width={3.00125}
      height={8.00333}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={19.0029}
      y={15.2514}
      width={3.00125}
      height={5.7524}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
