import React from 'react'

type Props = {
    title: string | JSX.Element,
    text: string,
}
export default function TextBlock1({ title, text } : Props) {
  return (
    <div className=' w-block1 w-full flex flex-col pr-3  '>
        <h1 className='title-level1'>{title}</h1>
        <p className='text-level1'>
            {text}
        </p>

    </div>
  )
}
