import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0641 19.784L18.4091 7.784C18.3081 7.326 17.9021 7 17.4331 7H6.56709C6.09809 7 5.69209 7.326 5.59109 7.784L2.93609 19.784C2.79809 20.408 3.27309 21 3.91209 21H20.0881C20.7271 21 21.2021 20.408 21.0641 19.784V19.784Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 7V4.5V4.5V4.5C14.5 3.119 13.381 2 12 2H11.999C10.618 2 9.49902 3.119 9.49902 4.5V4.5V4.5V7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1601 7.33984L15.1401 20.9998"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
