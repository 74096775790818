import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90402 15.507L9.04002 9.82503C9.12002 9.42903 9.42902 9.12003 9.82502 9.04103L15.507 7.90503C15.857 7.83503 16.165 8.14303 16.095 8.49303L14.959 14.175C14.88 14.571 14.57 14.88 14.175 14.959L8.49302 16.095C8.14302 16.166 7.83402 15.857 7.90402 15.507Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 21H7C4.791 21 3 19.209 3 17V7C3 4.791 4.791 3 7 3H17C19.209 3 21 4.791 21 7V17C21 19.209 19.209 21 17 21Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
