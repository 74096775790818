import React, { useEffect, useState } from 'react'
import { useAppContext } from '../context/appContext'
import { Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import NoContent from './NoContent';

export default function DeviceLayout() {
    const { winSize } = useAppContext()
    const [isLoaded, setisLoaded] = useState(false);
    const [isMobil, setisMobil] = useState(false);
    useWindowSize()
    useEffect(() => {
        if(winSize.w!== 0){
            if(winSize.w < 1270){
                setisMobil(true)
            }else{
                setisMobil(false)
            }
            setisLoaded(true)
        }
    }, [winSize.w])
    
 
    if(!isLoaded){
        return <Spin spinning/>
    }else if(isLoaded && !isMobil){
        return <Outlet/>
    }else{
        return (
          <NoContent/>
        )

    }
}
