import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.167 20.5H4.444C3.922 20.5 3.5 20.077 3.5 19.556V15.778C3.5 15.256 3.923 14.834 4.444 14.834H9.166"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.833 4.444V20.5H19.555C20.077 20.5 20.499 20.077 20.499 19.556V4.444C20.499 3.922 20.076 3.5 19.555 3.5H15.777C15.256 3.5 14.833 3.923 14.833 4.444H14.833Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.833 20.5H9.166V10.111C9.166 9.589 9.589 9.167 10.11 9.167H14.832"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
