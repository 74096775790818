import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99792 17.0022L11.905 17.0392"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.0007H17.0021"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0037 8.99874H2.99622"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99826 2.49609V5.49734"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0017 2.49609V5.49734"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0025 21.0037H5.99747C4.33978 21.0037 2.99622 19.6601 2.99622 18.0024V6.99783C2.99622 5.34014 4.33978 3.99658 5.99747 3.99658H18.0025C19.6602 3.99658 21.0037 5.34014 21.0037 6.99783V18.0024C21.0037 19.6601 19.6602 21.0037 18.0025 21.0037Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
