import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.81689 15.75H5.33389C5.68789 15.75 6.02689 15.61 6.27689 15.36V15.36C6.52689 15.11 6.66689 14.771 6.66689 14.417V9.083C6.66689 8.729 6.52689 8.39 6.27689 8.14V8.14C6.02689 7.89 5.68789 7.75 5.33389 7.75H4.06489"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.935 7.75H18.667C18.313 7.75 17.974 7.89 17.724 8.14V8.14C17.474 8.39 17.334 8.729 17.334 9.083V14.417C17.334 14.771 17.474 15.11 17.724 15.36V15.36C17.974 15.61 18.313 15.75 18.667 15.75H20.184"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.354 14.647H16.494C16.208 14.647 15.925 14.708 15.665 14.827L11.458 16.743"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.645 8.66791C10.141 8.37491 9.53199 8.30191 8.96999 8.50391L6.66699 9.33291"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.494 8.43578L14.618 7.16978C14.002 6.89878 13.29 6.95578 12.725 7.32178L10.115 9.01278C9.28495 9.54978 9.18995 10.7278 9.92195 11.3918V11.3918C10.371 11.7988 11.021 11.8968 11.57 11.6408L13.126 10.9148"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1261 10.9141L13.9161 11.6311C14.7891 12.4221 14.7921 13.7921 13.9241 14.5881L11.9071 16.4341C11.1461 17.1321 9.97807 17.1341 9.21407 16.4411L7.82807 15.1841C7.50007 14.8871 7.08207 14.7201 6.64307 14.6841"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
