import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 8V5C18 3.895 17.105 3 16 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H8"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 17V17C6.672 17 6 16.328 6 15.5V15.5C6 14.672 6.672 14 7.5 14V14C8.328 14 9 14.672 9 15.5V15.5C9 16.328 8.328 17 7.5 17Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 19.586V18.721C21 18.291 20.725 17.908 20.316 17.772L18 17V15L18.697 14.554C19.21 14.225 19.449 13.599 19.284 13.012V13.012C19.116 12.413 18.571 12 17.949 12H15.051C14.43 12 13.884 12.413 13.716 13.012V13.012C13.551 13.599 13.79 14.225 14.303 14.554L15 15V17L12.684 17.772C12.275 17.908 12 18.29 12 18.721V19.586C12 19.851 12.105 20.106 12.293 20.293L12.707 20.707C12.895 20.895 13.149 21 13.414 21H19.586C19.851 21 20.106 20.895 20.293 20.707L20.707 20.293C20.895 20.105 21 19.851 21 19.586Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7H14"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10.5H10"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
