import React from 'react'
import TextBlock1 from './TextBlock1'
import ImgBlock1 from './ImgBlock1'

type Props = {
    title: string | JSX.Element,
    text: string,
    img: string
}
export default function Block1({ title, text, img } : Props) {
  return (
    <div className=' w-max h-full flex relative items-center '>
      <div className='flex space-x-4 h-max w-max '>
         <TextBlock1
          title={title}
          text={text}
         />                   
       
        {/* <ImgBlock1 img={img}/> */}

      </div>

    </div>
  )
}
