import React, { useEffect } from 'react'
import { useAppContext } from '../context/appContext';
import { useLocation } from 'react-router-dom';


const getPage = (data:{title:string, key:string,blocks:{key:string, name:string,description:string, icon:string}[]}, key: string) => {
    return data.blocks.filter(el => el.key === key)?.[0]
}
type Props = {
    parent?: {
        key:string,
        name:string
    },
    data:{title:string, key:string,blocks:{key:string, name:string,description:string, icon:string}[]}[]
}
export default function useSetBreadcrumb({data, parent}: Props) {
    const { setBreadcrumb } = useAppContext()
    const location = useLocation()

    useEffect(() => {
        if(location.pathname.split('/').length ===2){
            setBreadcrumb([
                {
                    name: 'Home',
                    path:'/'
                },
                ...parent ? 
               [ {
                    name: parent.name,
                    path: parent.key
                }] : []
        ])
        }else{
            let item:any 
            data.some(t => item = getPage(t, location.pathname))
            if(item){
                setBreadcrumb([
                    {
                        name: 'Home',
                        path:'/'
                    },
                    ...parent ?
                    [{
                        name: parent.name,
                        path: parent.key
                    }] : [],
                    {
                        name: item.name,
                        path: item.key
                    }
                ])
            }
        }
        
    }, [location.pathname]);
  return {

  }
}
