import { getAuth, onAuthStateChanged } from '@firebase/auth'
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, OutletProps, useLocation } from 'react-router-dom'
import { auth } from '../../utils/firebase'
import Loading from './Loading'
import { useAppContext } from '../../context/appContext'


export default function PrivateRoute(props:OutletProps) {
    const [isLoaded, setisLoaded] = useState(false)
    const [user, setuser] = useState<null | {role?:string, id:string}>(null);
    const { setUser } = useAppContext()
    useEffect(() => {
      console.log('hi')
      const listen = onAuthStateChanged(auth, async user => {
        const claims = await user?.getIdTokenResult()
        console.log(user,'user')
        if(user){
            if((claims?.claims.role === 'super_admin' || claims?.claims.role === 'admin' || claims?.claims.role === 'guest')){
              setuser({id:user.uid,role:claims?.claims.role})
              setUser({name: user.displayName!, id: user.uid, photo: user.photoURL ? user.photoURL : undefined, email: user.email!})
            }else{
                setuser({id:user.uid})
                setUser(null)
            }
            console.log(user)
        }else{
            setuser(null)
            setUser(null)
        }
        setisLoaded(true)
      })
      return listen
    }, [])
console.log(user)

   
    if(!isLoaded){
      return <Loading/>
    }
    if(!user){
        return <Navigate to='/login' replace  /> 
    }
    if(user && !user.role){
        return <Navigate to='/unauthorized' replace  /> 
    }
    if(user?.role){
      return <Outlet {...props}/>
    }
    return <></>
 
}
