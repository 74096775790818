import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 5.5C19 6.881 15.866 8 12 8C8.134 8 5 6.881 5 5.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 9.5C19 10.881 15.866 12 12 12C8.134 12 5 10.881 5 9.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 13.5C19 14.881 15.866 16 12 16C8.134 16 5 14.881 5 13.5V5.5C5 4.119 8.134 3 12 3C15.866 3 19 4.119 19 5.5V13.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16V18.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 20H20"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20H10.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0607 18.9393C13.6465 19.5251 13.6465 20.4748 13.0607 21.0606C12.4749 21.6464 11.5252 21.6464 10.9394 21.0606C10.3536 20.4748 10.3536 19.5251 10.9394 18.9393C11.5252 18.3535 12.4749 18.3535 13.0607 18.9393"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
