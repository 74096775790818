import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';
import GlobalLayout from './pages/Layout';
import HomeLayout from './pages/home/HomeLayout';

import { AppContextProvider } from './context/appContext';
import Home from './pages/home/pages/Welcome';
import Platform from './pages/home/pages/Platform';
import Solution from './pages/home/pages/Solution';
import Avantages from './pages/home/pages/Avantages';
import HowItWorks from './pages/home/pages/HowItWorks';
import TheyJoined from './pages/home/pages/TheyJoined';
import Demo from './pages/home/pages/Demo';
import About from './pages/home/pages/About';
import Hiring from './pages/home/pages/Hiring';
import Contact from './pages/home/pages/Contact';

import Login from './pages/home/pages/login';
import PrivateRoute from './components/shared/PrivateRoute';
import DeviceLayout from './pages/DeviceLayout';
import './i18n'; 





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    
    <Route 
      element={<AppContextProvider><Outlet/></AppContextProvider>}
    >
       {/* Redirect from root to default language page */}
       <Route path="/" element={<Navigate to={`/en/`} replace />} />
        
        
        {/* Define routes for pages */}
      <Route element={<DeviceLayout/>}>
          <Route element={<Login/>} path=':lng/login'/>
          <Route element={<Navigate to={`/en/login`} replace />} path='/login'/>

        <Route element={<GlobalLayout/>}>
          <Route element={<PrivateRoute/>}>
            <Route element={<HomeLayout/>}>
              <Route element={<Home/>} path=':lng/'/>
              <Route element={<></>} path=':lng/home'/>
              <Route element={<></>} path='/home'/>


              <Route element={<Platform/>} path=':lng/home/platform'/>
              <Route element={<Solution/>} path=':lng/home/solution'/>


              {/* <Route element={<Products/>} >
                <Route path=':lng/home/products' element={<Files/>}/>
                <Route path=':lng/home/products/directory' element={<Directory/>}/>
                <Route path=':lng/home/products/folder' element={<Folder/>}/>
                <Route path=':lng/home/products/drive' element={<Drive/>}/>
                <Route path=':lng/home/products/server' element={<Server/>}/>
                <Route path=':lng/home/products/cluster' element={<Cluster/>}/>
                <Route path=':lng/home/products/domain' element={<Domain/>}/>



              </Route> */}

              <Route element={<Solution/>} path=':lng/home/solution'/>
              <Route element={<Avantages/>} path=':lng/home/avantages'/>
              <Route element={<HowItWorks/>} path=':lng/home/how-it-works'/>
              <Route element={<TheyJoined/>} path=':lng/home/joined'/>
              <Route element={<Demo/>} path=':lng/home/demo'/>

              <Route element={<About/>} path=':lng/home/about'/>
              <Route element={<Hiring/>} path=':lng/home/hiring'/>
              <Route element={<Contact/>} path=':lng/home/contact'/>






            </Route>
          
          </Route>
          
          
        </Route>
      
      </Route>

    </Route>
    
    </>

  )
);
root.render(
  <RouterProvider router={router} />
);

