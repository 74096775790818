import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 21H4.5C3.395 21 2.5 20.105 2.5 19V5C2.5 3.895 3.395 3 4.5 3H19.5C20.605 3 21.5 3.895 21.5 5V19C21.5 20.105 20.605 21 19.5 21Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 8H21.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.505 5.49C5.502 5.49 5.5 5.492 5.5 5.495C5.5 5.498 5.502 5.5 5.505 5.5C5.508 5.5 5.51 5.498 5.51 5.495C5.51 5.492 5.508 5.49 5.505 5.49"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.048 5.49C8.045 5.49 8.043 5.492 8.043 5.495C8.043 5.498 8.045 5.5 8.048 5.5C8.051 5.5 8.053 5.498 8.053 5.495C8.053 5.492 8.051 5.49 8.048 5.49"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.588 5.49C10.585 5.49 10.583 5.492 10.583 5.495C10.583 5.498 10.585 5.5 10.588 5.5C10.591 5.5 10.593 5.498 10.593 5.495C10.593 5.492 10.59 5.49 10.588 5.49"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
