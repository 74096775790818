import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 20H5C3.895 20 3 19.1738 3 18.1538V9.84615C3 8.82615 3.895 8 5 8H14C15.105 8 16 8.82615 16 9.84615V18.1538C16 19.1738 15.105 20 14 20Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8V5.85714C7 4.83107 7.83107 4 8.85714 4H18.1429C19.1689 4 20 4.83107 20 5.85714V15.1429C20 16.1689 19.1689 17 18.1429 17H16"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
