import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1111 7.5725H12.9996C12.687 7.5725 12.3942 7.429 12.2186 7.18925L10.8916 5.38238C10.7159 5.1435 10.4241 5 10.1115 5H5.88889C4.84528 5 4 5.78312 4 6.75V17.25C4 18.2169 4.84528 19 5.88889 19H19.1111C20.1547 19 21 18.2169 21 17.25V9.3225C21 8.3565 20.1547 7.5725 19.1111 7.5725Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
