import React from 'react'
import Icon from '../components/icons/Icon'
import LanguageSwitcher from '../components/Switch'

export default function NoContent() {
  return (
    <div className='w-full h-full flex flex-col'>
        <LanguageSwitcher />
        <div className="flex space-x-2 items-center mt-10 justify-center">
            <Icon name='TeddraLogo' className='w-[50px] h-[50px]'/>
            <p className='font-bold text-[17px]'>teddra</p>

        </div>
        <div className="flex flex-col items-center flex-1 justify-center space-y-3">
            
            <div className="flex items-center justify-center flex-wrap gap-y-4">
                <div className="space-y-2 px-7 py-3 rounded cursor-context-menu">
                    <div className="relative">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[40px] h-[40px] mx-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>
                    <div className="absolute right-0 bottom-0">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-[10px] h-[10px] text-green-700 mx-auto">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                    </div>
                    </div>
                    <div className="space-y-1 text-center">
                    <p className="">PC</p>

                    </div>

                </div>
                <div className="space-y-2 px-7 py-3 rounded cursor-context-menu">
                    <div className="relative">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="transparent" className="w-[40px] h-[40px] mx-auto text-teddra-gray-200">
                            <path d="M10.5 18a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" stroke='currentColor' />
                            <path fillRule="evenodd" d="M7.125 1.5A3.375 3.375 0 003.75 4.875v14.25A3.375 3.375 0 007.125 22.5h9.75a3.375 3.375 0 003.375-3.375V4.875A3.375 3.375 0 0016.875 1.5h-9.75zM6 4.875c0-.621.504-1.125 1.125-1.125h9.75c.621 0 1.125.504 1.125 1.125v14.25c0 .621-.504 1.125-1.125 1.125h-9.75A1.125 1.125 0 016 19.125V4.875z" clipRule="evenodd" stroke='currentColor' />
                        </svg>
                
                    </div>

                    <div className="space-y-1 text-center">
                        <p className=" text-teddra-gray-200">Tablette</p>

                    </div>

                </div>
                <div className="space-y-2 px-7 py-3 rounded cursor-context-menu">
                    <div className="relative">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="transparent" className="w-[40px] h-[40px] mx-auto text-teddra-gray-200">
                            <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" stroke='currentColor' />
                            <path fillRule="evenodd" d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z" clipRule="evenodd" stroke='currentColor' />
                        </svg>
                    

                    </div>

                    <div className="space-y-1 text-center">
                        <p className=" text-teddra-gray-200">Smartphone</p>
                    </div>

                </div>
            </div>
            <p className="text-center max-w-[300px] mx-auto">
            Cher visiteur, vous pouvez dès maintenant accéder à nos sites sur un écran PC. Les versions tablettes et smartphones sont en préparation. Merci de votre compréhension.
            </p>
        </div>

    </div>
  )
}
