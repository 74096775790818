import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'


export default function Loading() {
    const [open, setopen] = useState(false);
   
    useEffect(() => {
      setopen(true)
    }, [])
    
  return (
   <div className='absolute h-screen w-screen top-0 left-0 mask flex justify-center items-center'>
    <Spin spinning size='large'/>
   </div>
  )
}
