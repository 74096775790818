import React from 'react'
import Resource from './Resource'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'



export default function Resources({data} : {data: {title:string,key:string, blocks:{key:string,name:string,icon:string, description:string, disabled?:boolean, include?:boolean}[]}[]}) {
    const location = useLocation()
    const navigate = useNavigate()
    const { i18n } = useTranslation()
  return (
    <div className='space-y-16'>
        {data.map((block, i) => (
            <div className='' key={i}>
                <div className="flex flex-wrap gap-x-2 -ml-3">
                    {block.blocks.map(el => (
                        <Resource key={el.key} id={el.key} name={el.name} description={el.description} icon={el.icon} action={(key) => navigate(`/${i18n.language}${key}`)} active={el.include ? location.pathname.includes(el.key) : location.pathname === `/${i18n.language}${el.key}`} disabled={el.disabled}/>
                    ))}

                </div>
            </div>
        ))}
    </div>
  )
}
