import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.26517 8.23483C7.41161 8.38128 7.41161 8.61872 7.26517 8.76517C7.11872 8.91161 6.88128 8.91161 6.73483 8.76517C6.58839 8.61872 6.58839 8.38128 6.73483 8.23483C6.88128 8.08839 7.11872 8.08839 7.26517 8.23483"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2652 8.23483C12.4116 8.38128 12.4116 8.61872 12.2652 8.76517C12.1187 8.91161 11.8813 8.91161 11.7348 8.76517C11.5884 8.61872 11.5884 8.38128 11.7348 8.23483C11.8813 8.08839 12.1187 8.08839 12.2652 8.23483"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2652 8.23483C17.4116 8.38128 17.4116 8.61872 17.2652 8.76517C17.1187 8.91161 16.8813 8.91161 16.7348 8.76517C16.5884 8.61872 16.5884 8.38128 16.7348 8.23483C16.8813 8.08839 17.1187 8.08839 17.2652 8.23483"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.573 21.289L12.078 13.319C11.815 12.478 12.676 11.725 13.474 12.098L20.915 15.571C21.797 15.983 21.648 17.28 20.696 17.481L17.633 18.129L16.496 21.325C16.17 22.243 14.864 22.218 14.573 21.289Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 12.01V4.99C21 3.891 20.105 3 19 3H5C3.895 3 3 3.891 3 4.99V12.011C3 13.109 3.895 14 5 14H9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
