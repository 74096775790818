import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.47644 8.50105V9.50145"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.47772 8.50105V9.50145"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.504 9.00125H18.0313"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.40072 6H19.5069C20.3357 6 21.0075 6.67185 21.0075 7.50065V10.6018C21.0075 11.3754 20.3804 12.0026 19.6068 12.0026H4.40072C3.62712 12.0026 3 11.3754 3 10.6018V7.40075C3 6.62713 3.62712 6 4.40072 6Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0038 12.0013V15.4813"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5038 16.9812H20.0038"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.00378 16.9812H10.5038"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0645 15.9206C13.6503 16.5064 13.6503 17.4561 13.0645 18.0419C12.4787 18.6277 11.529 18.6277 10.9432 18.0419C10.3574 17.4561 10.3574 16.5064 10.9432 15.9206C11.529 15.3348 12.4787 15.3348 13.0645 15.9206Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
