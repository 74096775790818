import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.99625 7.99833H21.0038"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8655 5.49229L10.8605 5.49729L10.8655 5.5023L10.8705 5.49729L10.8655 5.49229"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6236 16.9416L16.1551 15.4521C15.9554 15.3855 15.7352 15.4375 15.5863 15.5863C15.4375 15.7352 15.3855 15.9554 15.452 16.1551L16.9416 20.6236C17.0102 20.8295 17.1925 20.9766 17.4082 21.0003C17.624 21.024 17.8338 20.9199 17.9454 20.7338L18.8481 19.2293C18.942 19.0729 19.0729 18.9419 19.2294 18.8481L20.7338 17.9454C20.9199 17.8337 21.024 17.6239 21.0003 17.4082C20.9766 17.1924 20.8295 17.0102 20.6236 16.9416Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7185 20.7185L19.0153 19.0153"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0509 12.3812L18.5097 12.9374L19.0509 12.3812"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.716 10.9996L15.726 11.7755"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3812 12.3812L12.9374 12.9214L12.3812 12.3812"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 15.7155L11.7759 15.7045L10.9996 15.7155"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3812 19.0509L12.9374 18.5097L12.3812 19.0509"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.32547 5.49229L8.32047 5.49729L8.32547 5.5023L8.33047 5.49729L8.32547 5.49229"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.77641 5.48729L5.77141 5.49229L5.77641 5.49729L5.78141 5.49229L5.77641 5.48729"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99917 21.0037H4.99709C4.46639 21.0037 3.95738 20.7931 3.58212 20.4179C3.20686 20.0426 2.99625 19.5336 2.99625 19.0029V4.99708C2.99625 4.46638 3.20686 3.95738 3.58212 3.58212C3.95738 3.20686 4.46639 2.99625 4.99709 2.99625H19.0029C19.5336 2.99625 20.0426 3.20686 20.4179 3.58212C20.7931 3.95738 21.0038 4.46638 21.0038 4.99708V9.99916"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
