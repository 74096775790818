import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'

import Icon from '../../../components/icons/Icon'
import { useTranslation } from 'react-i18next'


export default function Solution() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const {t}:any = useTranslation(['common', 'solution'])

  const [scrollTo, setscrollTo] = useState(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop )
  }
  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.solutions.label')}`
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="">
                        <div className='space-y-7'>
                         
                            <div className='px-page h-[calc(100vh-192px)] min-h-[1100px] pt-page space-y-[90px]'>
                                <div className='text-center'>
                                    <p className="label-level2 ">{t('resources.solutions.label')}</p>
                                    <p className='title-level1  w-full'>{t('resources.solutions.titlePage')} </p>
                                    <p className="text-level1">
                                    {t('solution:header.text')}
                                    </p>
                                </div>
                                <div className="grid grid-cols-4  gap-x-[30px] w-full justify-center ">
                                    <div className='text-center'>
                                        <div className='space-y-5'>
                                            <Icon name='Annuiar' className='w-[60px] h-[60px] mx-auto'/>
                                            <div>
                                                <p className="text-level2 font-bold">{t('solution:header.p1.title')}</p>
                                                <p className="text-level2">
                                                {t('solution:header.p1.text')}
                                                </p>

                                            </div>

                                        </div>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref1)}>
                                            {t('btn')}
                                        </button>
                                    </div>
                                    <div className='text-center'>
                                        <div className='space-y-5'>
                                            <Icon name='Community' className='w-[60px] h-[60px] mx-auto'/>
                                            <div>
                                                <p className="text-level2 font-bold">{t('solution:header.p2.title')}</p>
                                                <p className="text-level2">
                                                {t('solution:header.p2.text')}
                                                </p>

                                            </div>
                                        </div>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref2)}>
                                            {t('btn')}
                                        </button>
                                    </div>
                                    <div className='text-center'>
                                        <div className='space-y-5'>
                                            <Icon name='Publisher' className='w-[60px] h-[60px] mx-auto'/>
                                            <div>
                                                <p className="text-level2 font-bold">{t('solution:header.p3.title')}</p>
                                                <p className="text-level2">
                                                {t('solution:header.p3.text')}
                                                </p>

                                            </div>
                                        </div>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref3)}>
                                            {t('btn')}
                                        </button>
                                    </div>
                                    
                                    <div className='text-center'>
                                        <div className="space-y-5">
                                            <Icon name='Brand' className='w-[60px] h-[60px] mx-auto'/>
                                            <div>
                                                <p className="text-level2 font-bold">{t('solution:header.p4.title')}</p>
                                                <p className="text-level2">
                                                {t('solution:header.p4.text')}
                                                </p>
                                            </div>
                                        </div>
                                        <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn mx-auto' onClick={() => scrollToSection(ref4)}>
                                            {t('btn')}
                                        </button>
                                    </div>

                                            
                                </div>

                            </div>
                           

                        </div>
                       
                        <div className="">
                        <div className="">
                                <div className='flex space-x-[55px] justify-between items-center px-page pb-block '>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='Annuiar' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]" ref={ref1}>
                                        <div className=''>
                                            <TextBlock2
                                                label={t('solution:p1.label')}
                                                title={t('solution:p1.title')}
                                                text={t('solution:p1.text')}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <div className='space-y-3'>
                                                <div className='space-y-8'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem1')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem2')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 ">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem3')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem4')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem5')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 ">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Check'/>
                                                        </div>
                                                        <p className='text-level2 mt-0 flex-1'>{t('solution:p1.groupItem6')}</p>
                                                    </div>
                                                
                                                    

                                                </div>                   
                                                    
                                            </div>  
                                           

                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                            <div className="">
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]" ref={ref2}>
                                            <div className=''>
                                                <TextBlock2
                                                    label={t('solution:p2.label')}
                                                    title={t('solution:p2.title')}
                                                    text={t('solution:p2.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-x-8 flex">
                                                <p className='text-level2 max-w-[193px] '>{t('solution:p2.groupe')}</p>
                                                <div className=' flex-1'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Folders'/>
                                                            </div>
                                                            <p className='text-level2'>Folders</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                                <div className='icon-lg  '>
                                                                    <Icon className='w-full h-full  ' name='Directory'/>
                                                                </div>
                                                                <p className='text-level2'>Directory</p>
                                                            </div>
                                                       
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Drive'/>
                                                            </div>
                                                            <p className='text-level2'>Drive</p>
                                                        </div>
                                                        
                                                    </div>
                                                  
                                                
                                                
                                                
                                                
                                                </div>

                                            </div>   
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Community' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                            <div className="">
                                <div className='flex space-x-[55px] justify-between items-center px-page py-block '>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='Publisher' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]" ref={ref3}>
                                        <div className=''>
                                            <TextBlock2
                                                label={t('solution:p3.label')}
                                                title={t('solution:p3.title')}
                                                text={t('solution:p3.text')}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 '>{t('solution:p3.groupe')}</p>
                                            <div className='w-full'>
                                                
                                                <div className='space-y-3'>
                                                
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Thematic Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Regional Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Cluster'/>
                                                        </div>
                                                        <p className='text-level2'>Cluster</p>
                                                    </div>

                                                </div>
                                                
                                            
                                            
                                            
                                            </div>

                                        </div>

                                    </div>
                                    
                                </div>

                            </div>

                     

                            <div className="">
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]" ref={ref4}>
                                            <div className=''>
                                                <TextBlock2
                                                    label={t('solution:p4.label')}
                                                    title={t('solution:p4.title')}
                                                    text={t('solution:p4.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-x-8 flex">
                                                <p className='text-level2 max-w-[193px] '>{t('solution:p4.groupe')}</p>
                                                <div className=' flex-1'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Server'/>
                                                            </div>
                                                            <p className='text-level2'>Servers</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                                <div className='icon-lg  '>
                                                                    <Icon className='w-full h-full  ' name='Globe'/>
                                                                </div>
                                                                <p className='text-level2'>Domain</p>
                                                            </div>
                                                       
                                                       
                                                        
                                                    </div>
                                                  
                                                
                                                
                                                
                                                
                                                </div>

                                            </div> 
                                           
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Brand' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                          
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
