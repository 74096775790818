import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8825 21.004H6.11758C4.88416 21.004 3.85141 20.0694 3.72858 18.8421L3.00828 11.6391C2.94073 10.9641 3.16187 10.292 3.61703 9.78904C4.0722 9.28603 4.7189 8.99902 5.39728 8.99902H18.6028C19.2812 8.99902 19.9279 9.28603 20.383 9.78904C20.8382 10.292 21.0593 10.9641 20.9918 11.6391L20.2715 18.8421C20.1486 20.0694 19.1159 21.004 17.8825 21.004V21.004Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99744 5.99777H18.0024"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99829 2.9963H16.0016"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.4989 12.7502H14.501"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
