import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import Block1 from '../../../components/details/blocks/block1'


export default function TheyJoined() {
  const { winSize } = useAppContext()
  return (
    <div className="w-full h-full">
        <DetailsLayout navigateBack='/home'>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                     
                         <div className="flex space-between-2cols items-center">
                            <div>
                                <p className="text-[13px] uppercase font-extrabold color-inverted">Ils sont rejoint</p>
                                <Block1
                                    title={'Lorem ipsum dolor'}
                                    text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi praesentium aliquid quo commodi perspiciatis'
                                    img=''
                                />

                            </div>
                            <div className="w-[500px] h-[500px] bg-skin-fill-muted"></div>
                        </div>
                      
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
