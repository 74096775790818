import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.07 8H10.489C9.97496 8 9.55896 8.416 9.55896 8.93V12.279C9.55896 12.793 9.97496 13.209 10.489 13.209H16.07C16.584 13.209 17 12.793 17 12.279V8.93C17 8.416 16.584 8 16.07 8Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.443 13.2091V15.0701C14.443 15.5841 14.027 16.0001 13.513 16.0001H7.93C7.416 16.0001 7 15.5841 7 15.0701V11.4891C7 10.9751 7.416 10.5591 7.93 10.5591H9.558"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
