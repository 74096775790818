import React from 'react'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import DetailsLayoutLg from './DetailsLayoutLg'
import DetrailsLayoutSm from './DetrailsLayoutSm'

export default function DetailsLayout({children, navigateBack, fullWidth, hidden, scrollTo}: {children:JSX.Element, navigateBack:string, fullWidth?:boolean,hidden?:boolean, scrollTo? : number}) {
   const { winSize } = useAppContext()
  return (
    <div className='w-full h-full'>
        {winSize.w > maxLgWinWidth ? (
            <DetailsLayoutLg fullWidth={fullWidth} hidden={hidden} scrollTo={scrollTo}> 
                {children}
            </DetailsLayoutLg>
        ) : (
            <DetrailsLayoutSm navigateBack={navigateBack} fullWidth={fullWidth} hidden={hidden} scrollTo={scrollTo}>
                {children}
            </DetrailsLayoutSm>
        )}
    </div>
  )
}
