import React, { useEffect } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import Block1 from '../../../components/details/blocks/block1'
import { useTranslation } from 'react-i18next'
import Icon from '../../../components/icons/Icon'
import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'


export default function About() {
  const { winSize } = useAppContext()
  const { t }:any = useTranslation(['common', 'about'])

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full">
        <DetailsLayout navigateBack='/home'>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols pt-page pl-page pr-[30px] min-h-[calc(100vh-300px)]">
                                <div className='flex items-center space-x-14'>
                                    <div className='w-[calc(100%-546px)]'>
                                        <p className="label-level2 ">{t('resources')?.about?.label}</p>
                                        <p className='title-level1'>
                                            {t('resources')?.about?.title}
                                        </p>
                                        <p className="text-level1">
                                            {t('about:header.text')}
                                        </p>
                                    </div>
                                    <div className='flex items-center justify-center flex-1 h-[513px] '>
                                        <Icon name='Team' className='w-full h-full'/>
                                    </div>
                                </div>
                            </div>
                           

                        </div>
                        <div className=" px-page pb-block">
                            <div className='flex space-x-[90px] justify-between  items-center  '>
                                <div className=" w-[50%]">
                                    <div className=''>
                                        <TextBlock2
                                            label={t('about:p1.label')}
                                            title={t('about:p1.title')}
                                            text={t('about:p1.text')}
                                            
                                        />
                                        

                                    </div>
                                    

                                </div>
                                <div className=" w-[38%] h-auto">
                                    <Icon name='GlobeSearch' className='w-full h-dull'/>
                                </div>
                            </div>
                        
                        </div>
                        <div className=" px-page pb-block">
                            <div className='flex space-x-[90px] justify-between  items-center  '>
                                <div className=" w-[50%]">
                                    <div className=''>
                                        <TextBlock2
                                            label={t('about:p2.label')}
                                            title={t('about:p2.title')}
                                            text={t('about:p2.text')}
                                            
                                        />
                                        

                                    </div>
                                    

                                </div>
                              
                            </div>
                        
                        </div>
                        <div className=" px-page pb-block">
                            <div className='flex space-x-[90px] justify-between  items-center  '>
                                <div className=" ">
                                    <div className='w-[50%]'>
                                        <TextBlock2
                                            label={t('about:p3.label')}
                                            title={t('about:p3.title')}
                                            text={t('about:p3.text')}
                                            
                                        />
                                        

                                    </div>  
                                    <div className='mt-10 grid grid-cols-3 gap-[50px] w-full'>
                                        <div>
                                            <div className="space-y-5">
                                                <div className='w-[100px] h-[122px] rounded border'></div>
                                                <div>
                                                    <p className="text-level2 font-bold">{t('about:team.maamar.name')}</p>
                                                    <p className="text-level2">
                                                    {t('about:team.maamar.position')}
                                                    </p>

                                                </div>

                                            </div>
                                            <button className='px-4 py-2 border rounded mt-7 text-[15px] btn flex space-x-3 items-center' onClick={() => window.open('')}>
                                                <Icon className='icon-sm' name='Linkedin'/>
                                                <p>Linkedin profil</p>
                                            </button>
                                        </div>
                                        <div>
                                            <div className="space-y-5">
                                                <div className='w-[100px] h-[122px] rounded border'></div>
                                                <div>
                                                    <p className="text-level2 font-bold">{t('about:team.maamar.name')}</p>
                                                    <p className="text-level2">
                                                    {t('about:team.maamar.position')}
                                                    </p>

                                                </div>

                                            </div>
                                            <button className='px-4 py-2 border rounded mt-7 text-[15px] btn flex space-x-3 items-center' onClick={() => window.open('')}>
                                                <Icon className='icon-sm' name='Linkedin'/>
                                                <p>Linkedin profil</p>
                                            </button>
                                        </div>
                                        <div>
                                            <div className="space-y-5">
                                                <div className='w-[100px] h-[122px] rounded border'></div>
                                                <div>
                                                    <p className="text-level2 font-bold">{t('about:team.maamar.name')}</p>
                                                    <p className="text-level2">
                                                    {t('about:team.maamar.position')}
                                                    </p>

                                                </div>

                                            </div>
                                            <button className='px-4 py-2 border rounded mt-7 text-[15px] btn flex space-x-3 items-center' onClick={() => window.open('')}>
                                                <Icon className='icon-sm' name='Linkedin'/>
                                                <p>Linkedin profil</p>
                                            </button>
                                        </div>
                                    </div>                         
                                </div>
                              
                            </div>
                        
                        </div>
                        <div className=' px-page py-block'>
                            <div className='flex justify-between items-center space-x-[55px]  '>
                            
                                <div className=" left-0 w-[38%] h-auto">
                                    <Icon name='Access' className='w-full h-dull'/>
                                </div>
                                <div className=" w-[50%]">
                                    <div className="">
                                        <TextBlock2
                                            label={t('htw:p2.label')}
                                            title={t('htw:p2.title')}
                                            text={t('htw:p2.text')}
                                        />

                                    </div>
                                    <div className="mt-10 space-y-8 ">
                                        <div className='space-y-3'>
                                            <div className='space-y-8'>
                                                <div className="flex space-x-3 items-center">
                                                    <div className='icon-lg'>
                                                        <Icon className='w-full h-full' name='Check'/>
                                                    </div>
                                                    <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem1')}</p>
                                                </div>
                                                <div className="flex space-x-3 items-center">
                                                    <div className='icon-lg'>
                                                        <Icon className='w-full h-full' name='Check'/>
                                                    </div>
                                                    <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem2')}</p>
                                                </div>
                                            
                                                <div className="flex space-x-3 items-center">
                                                    <div className='icon-lg'>
                                                        <Icon className='w-full h-full' name='Check'/>
                                                    </div>
                                                    <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem3')}</p>
                                                </div>
                                            
                                                <div className="flex space-x-3 items-center">
                                                    <div className='icon-lg'>
                                                        <Icon className='w-full h-full' name='Check'/>
                                                    </div>
                                                    <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem4')}</p>
                                                </div>
                                            
                                            
                                                

                                            </div>
                                            
                                        
                                        
                                        
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                      
                    
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
