import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="transparent" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 10.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 9 10.5M16.19 8a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 16.19 8M21.569 16a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001M21 12H10.5M7.5 12H3M18.57 16H3.93M13.19 8H3.93M20.07 8h-3.88"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.881 9.489c.077.797.119 1.639.119 2.511 0 4.971-1.343 9-3 9-1.487 0-2.72-3.244-2.958-7.501M14.382 6.531C13.834 4.385 12.971 3 12 3c-1.486 0-2.72 3.244-2.958 7.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.617 14.601A9 9 0 0 0 12 3a9 9 0 0 0-9 9 9 9 0 0 0 16.285 5.282"
    />
  </svg>
)
export default SvgComponent
