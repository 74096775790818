import React from 'react'
import OuterContainer from './OuterContainer'

export default function DetailsLayoutLg({children, fullWidth, hidden, scrollTo}: {children:JSX.Element, fullWidth?:boolean,hidden?:boolean,scrollTo? : number}) {
  return (
  <>
    {fullWidth ? (
      children
    ) : (
      <OuterContainer size='lg' hidden={hidden} scrollTo={scrollTo}>
        {children}
      </OuterContainer>
    )}
  
  </>
     
  )
}
