import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 17L16 15L13.052 13.019L11.109 12.895L10 14L12 17H15Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.813 3.03492C7.18904 2.54592 2.54604 7.18892 3.03504 12.8129C3.40704 17.0919 6.90704 20.5919 11.186 20.9639C16.81 21.4529 21.453 16.8109 20.964 11.1859C20.592 6.90792 17.092 3.40792 12.813 3.03492Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.95105 7.96095L8.00005 10.9999L9.00505 9.00495L13 7.99995L14.182 3.27295"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
