import React from 'react'

export default function PageContainer({isLg, children} : {isLg:boolean, children:JSX.Element}) {
  return (
    // <div className={`bg-white ${isLg ? 'w-[42vw] h-[63vw]' : 'w-[800px] h-[1200px]'} flex`} style={{boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 10px'}}>
      <div className={`bg-white ${isLg ? 'w-full min-h-[81vw]' : 'w-full min-h-[1200px]'} h-max  flex border`} style={{borderColor: '#ccc', boxShadow:'-4px -3px 11px rgba(0,0,0,0.1)'}}>

        
            {children}
            
    </div>
  )
}
