import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.99582}
      y={2.99625}
      width={18.0075}
      height={3.00125}
      rx={1}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.0025H4.99708C3.89205 18.0025 2.99625 17.1067 2.99625 16.0017V5.9975H19.0029"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 9.99916H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 13.0004H8.99876"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={17.9025}
      cy={9.99917}
      r={1.50063}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2021 22.0042H18.8028C19.0728 22.0205 19.3369 21.9203 19.5281 21.7291C19.7194 21.5378 19.8195 21.2737 19.8032 21.0038V18.7028L20.8036 17.5023C20.9146 17.3198 20.983 17.1146 21.0037 16.902V15.0013C21.02 14.7313 20.9199 14.4672 20.7286 14.2759C20.5374 14.0847 20.2733 13.9845 20.0033 14.0008H16.0016C15.7317 13.9845 15.4676 14.0847 15.2763 14.2759C15.0851 14.4672 14.9849 14.7313 15.0012 15.0013V16.902C15.022 17.1146 15.0904 17.3198 15.2013 17.5023L16.2017 18.7028V21.0038C16.1854 21.2737 16.2856 21.5378 16.4768 21.7291C16.6681 21.9203 16.9322 22.0205 17.2021 22.0042V22.0042Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
