import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 21H8.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 10H20C20.5523 10 21 10.4477 21 11V13C21 14.1046 20.1046 15 19 15H18.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10H4C3.44772 10 3 10.4477 3 11V13C3 14.1046 3.89543 15 5 15H5.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={5.5}
      y={13}
      width={13}
      height={4}
      rx={2}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.667 13L16.4236 11.9376C16.2909 11.3586 16.2624 10.7605 16.3396 10.1715L16.9828 5.25963C17.0575 4.68881 16.8826 4.11347 16.5029 3.68077C16.1231 3.24808 15.5754 3 14.9997 3H9.0003C8.42462 3.00002 7.87686 3.24811 7.49714 3.6808C7.11742 4.11349 6.94257 4.68883 7.0173 5.25964L7.6605 10.1714C7.73765 10.7605 7.7092 11.3585 7.5765 11.9376L7.33299 13"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
