import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { ButtonLg } from "../Buttons/ButtonLg"
import { Dropdown } from "antd"
import { routeType } from "../../../types"

type Props = {
    routes : routeType[],
    maxElement : number
}

export const Breadcrumb = (props:Props) => {
    const navigate = useNavigate()
    return (
        <div className={`w-full input-h rounded border border-main flex justify-between bg-sub-windows `}>
            <div className="flex space-x-0.5 items-center flex-1  rounded  py-0.5">
                {props.routes.map((route,i) => (
                    <>
                    {!route.action ? (
                        <>
                            <ButtonLg
                                key={i}
                                buttonProps={{
                                    onClick:() => route.path ? navigate(route.path) : {},
                                }}
                                prefix={route.icon && {
                                    icon: route.icon,
                                    size:'sm'
                                }}
                                
                            >
                                <p className={`${(i===props.routes.length -1) && 'font-bold'}`}>{route.name}</p>
                            </ButtonLg>
                        
                            {i < props.maxElement -1 && <ChevronRightIcon className="w-3.5 h-3.5"/>}
                        
                        </>
                    ) : (
                        <Dropdown
                            overlay={route.action}
                            trigger={['click']}
                        >
                            <button className="flex space-x-0.5 items-center ">

                                <ButtonLg
                                    key={i}
                                    buttonProps={{
                                        onClick:() => route.path ? navigate(route.path) : {},
                                    }}
                                    prefix={route.icon && {
                                        icon: route.icon,
                                        size:'sm'
                                    }}
                                    
                                >
                                    <p className={`${(i===props.routes.length -1) && 'font-bold'}`}>{route.name}</p>
                                </ButtonLg>
                                
                                {i < props.maxElement -1 && <ChevronRightIcon className="w-3.5 h-3.5"/>}
                            </button>

                        </Dropdown>
                    )}
                    
                    </>

                ))}
                
            </div>
        </div>
    )
}