import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { useTranslation } from 'react-i18next'


export default function Avantages() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const {t}:any = useTranslation(['common', 'avantage'])

  const [scrollTo, setscrollTo] = useState<any>(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop - 20)
  }

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.avantages.label')}`
  }, []);

  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols px-page pt-page h-[calc(100vh-192px)] min-h-[1000px]">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='max-w-[530px]'>
                                            <p className="label-level2 ">{t('resources.avantages.label')}</p>
                                            <p className='title-level1'>{t('resources.avantages.title')}</p>
                                            <p className="text-level1">
                                            {t('avantage:header.text')}
                                            </p>
                                           

                                        </div>
                                    
                                        <div className="grid grid-cols-4 gap-x-[30px] w-full">
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='GlobeSearch' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('avantage:header.p1.title')}</p>
                                                        <p className="text-level2">
                                                        {t('avantage:header.p1.text')}

                                                        </p>
                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref1)}>
                                                    {t('btn')}
                                                </button>
                                            </div>
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='GlobeNetwork' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('avantage:header.p2.title')}</p>
                                                        <p className="text-level2">
                                                            {t('avantage:header.p2.text')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    {t('btn')}
                                                </button>
                                            </div>
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='GlobeStat' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('avantage:header.p3.title')}</p>
                                                        <p className="text-level2">
                                                        {t('avantage:header.p3.text')}
                                                        </p>

                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    {t('btn')}
                                                </button>
                                            </div>
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='GlobMoney' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('avantage:header.p4.title')}</p>
                                                        <p className="text-level2">
                                                        {t('avantage:header.p4.text')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref4)}>
                                                {t('btn')}
                                                </button>
                                            </div>

                                            
                                        </div>

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-0'>
                                    <Icon name='Disque' className='w-full h-full'/>

                                </div> */}
                            </div>
                           

                        </div>
                       
                      
                        <div className="">
                            <div className=" px-page pb-block">
                                <div className='flex space-x-[90px] justify-between  items-center  ' ref={ref1}>
                                    <div className=" w-[50%]">
                                        <div className=''>
                                            <TextBlock2
                                                label={t('avantage:p1.label')}
                                                title={t('avantage:p1.title')}
                                                text={t('avantage:p1.text')}
                                               
                                            />
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('avantage:p1.groupItem1')}
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            {t('avantage:p1.groupItem2')}
 
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            {t('avantage:p1.groupItem3')}
 
 
                                                            </p>
                                                        </div>
                                                 
                                                    
                                                    
                                                        

                                                    </div>                   
                                                        
                                                </div>  
                                            

                                            </div>

                                        </div>
                                        

                                    </div>
                                    {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                                    <div className=" w-[38%] h-auto">
                                        <Icon name='GlobeSearch' className='w-full h-dull'/>
                                    </div>
                                </div>
                               

                            </div>
                            <div className=' px-page py-block'>
                                <div className='flex items-center space-x-[90px] justify-between ' ref={ref2}>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='GlobeNetwork' className='w-full h-dull'/>
                                    </div>
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label={t('avantage:p2.label')}
                                                title={t('avantage:p2.title')}
                                                text={t('avantage:p2.text')}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            {t('avantage:p2.groupItem1')}
 
                                                            </p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>
                                                            {t('avantage:p2.groupItem2')}
 
 
                                                            </p>
                                                        </div>

                                                    </div>                   
                                                        
                                                </div>  
                                            

                                            </div>
                                    </div>
                                </div>
                             

                            </div>

                            <div className=' px-page py-block'>
                                <div className='flex justify-between items-center space-x-[90px]  ' ref={ref3}>
                                 
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label={t('avantage:p3.label')}
                                                title={t('avantage:p3.title')}
                                                text={t('avantage:p3.text')}
                                            />

                                        </div>
                                    </div>
                                    <div className=" left-0 w-[38%] h-auto">
                                        <Icon name='GlobeStat' className='w-full h-dull'/>
                                    </div>
                                </div>
                           

                            </div>
                          
                            <div className=" px-page py-block">
                                <div className='space-y-4' ref={ref4}>
                                    <div className='flex justify-between  space-x-[90px] items-center'>
                                        <div className="w-[38%] h-auto">
                                            <Icon name='GlobMoney' className='w-full h-dull'/>
                                        </div>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label={t('avantage:p4.label')}
                                                    title={t('avantage:p4.title')}
                                                    text={t('avantage:p4.text')}
                                                />

                                            </div>
                                            

                                        </div>
                                     
                                      
                                    </div>
                             
                                
                                </div>
                            </div>
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
