import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7678 10.9822C14.7441 11.9585 14.7441 13.5414 13.7678 14.5177C12.7915 15.494 11.2086 15.494 10.2323 14.5177C9.25595 13.5414 9.25595 11.9585 10.2323 10.9822C11.2086 10.0059 12.7915 10.0059 13.7678 10.9822"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2538 4.98223C20.2301 5.95854 20.2301 7.54145 19.2538 8.51776C18.2775 9.49407 16.6946 9.49407 15.7183 8.51776C14.742 7.54145 14.742 5.95854 15.7183 4.98223C16.6946 4.00592 18.2775 4.00592 19.2538 4.98223"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 12.25C19.533 12.25 21 12.783 22 13.583"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.28177 4.98223C9.25808 5.95854 9.25808 7.54145 8.28177 8.51776C7.30546 9.49407 5.72255 9.49407 4.74624 8.51776C3.76993 7.54145 3.76993 5.95854 4.74624 4.98223C5.72255 4.00592 7.30546 4.00592 8.28177 4.98223"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 13.583C3 12.783 4.467 12.25 6 12.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.591 19.75C15.426 18.849 13.752 18.25 12 18.25C10.248 18.25 8.574 18.849 7.409 19.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
