import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 7.60001H21.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 16.75H4.5C3.395 16.75 2.5 15.855 2.5 14.75V5.75C2.5 4.645 3.395 3.75 4.5 3.75H19.5C20.605 3.75 21.5 4.645 21.5 5.75V14.75C21.5 15.855 20.605 16.75 19.5 16.75H17"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.75C14.75 21.75 17 19.5 17 16.75C17 14 14.75 11.75 12 11.75C9.25 11.75 7 14 7 16.75C7 17.872 7.379 18.907 8.009 19.743C8.923 20.958 10.372 21.75 12 21.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.5V13.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19V19.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.698 18.464C10.892 18.782 11.224 19.002 11.625 19.002H12H12.447C13.029 19.002 13.5 18.53 13.5 17.949C13.5 17.466 13.171 17.045 12.703 16.927L11.297 16.574C10.829 16.456 10.5 16.035 10.5 15.552C10.5 14.97 10.972 14.499 11.553 14.499H12H12.375C12.775 14.499 13.106 14.718 13.3 15.035"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
