import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0025 14.0007V19.0028C18.0025 20.1078 17.1067 21.0036 16.0016 21.0036H4.99705C3.89202 21.0036 2.99622 20.1078 2.99622 19.0028V4.99693C2.99622 3.8919 3.89202 2.99609 4.99705 2.99609H16.0016C17.1067 2.99609 18.0025 3.8919 18.0025 4.99693V7.99818"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0004 13.4143V16.0014H15.5874C15.8527 16.0014 16.1072 15.8959 16.2947 15.7083L20.3824 11.6206C21.2108 10.7917 21.2108 9.44827 20.3824 8.61934V8.61934C19.5535 7.79095 18.2101 7.79095 17.3812 8.61934L13.2935 12.707C13.1059 12.8946 13.0004 13.149 13.0004 13.4143Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 12.0002H9.99917"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 16.0022H9.99917"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 7.99826H14.0008"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
