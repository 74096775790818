import React, { useEffect } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useAppContext } from "../../../context/appContext";
import DetailsLayout from "../../../components/details/layout";
import { useTranslation } from "react-i18next";

export default function Demo(){
  const { user } = useAppContext()
  const { t }:any = useTranslation(['common'])
  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.demo.label')}`
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home'>
            <div className="w-full h-full">
                {user && (
                    <InlineWidget url={`https://calendly.com/rania-teddra?name=${encodeURIComponent(user.name)}&email=${encodeURIComponent(user.email)}&primary_color=000`} />

                )}
            
            </div>

        </DetailsLayout>
    </div>
//       <iframe
//     src="https://tidycal.com/saadirania406"
//     width="100%"
//     height="100%"
//     frameBorder="0"
//     allow="autoplay; encrypted-media"
//     title="Book a Demo"
//   ></iframe>
  );
};

