import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      d="M6 13H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 17H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.999 5.775C18.875 5.775 18.774 5.876 18.775 6C18.775 6.124 18.876 6.225 19 6.225C19.124 6.225 19.225 6.124 19.225 6C19.225 5.876 19.124 5.775 18.999 5.775"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 19C2 20.105 2.895 21 4 21H14C15.105 21 16 20.105 16 19V3H4C2.895 3 2 3.895 2 5V19Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3H20C21.105 3 22 3.895 22 5V7C22 8.105 21.105 9 20 9H2"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
