import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.99585}
      y={2.99658}
      width={18.0075}
      height={3.00125}
      rx={1}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 18.0026H4.99717C3.89214 18.0026 2.99634 17.1068 2.99634 16.0017V5.99756H19.003"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99805 9.99923H12.0001"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99805 13.0007H8.99888"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={17.9025}
      cy={9.99916}
      r={1.50063}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2024 22.0041H18.8031C19.0731 22.0204 19.3371 21.9202 19.5284 21.729C19.7196 21.5377 19.8198 21.2737 19.8035 21.0037V18.7027L20.8039 17.5022C20.9148 17.3197 20.9832 17.1145 21.004 16.902V15.0012C21.0203 14.7312 20.9201 14.4671 20.7289 14.2759C20.5376 14.0846 20.2736 13.9844 20.0036 14.0007H16.0019C15.7319 13.9844 15.4679 14.0846 15.2766 14.2759C15.0853 14.4671 14.9852 14.7312 15.0015 15.0012V16.902C15.0222 17.1145 15.0906 17.3197 15.2016 17.5022L16.202 18.7027V21.0037C16.1857 21.2737 16.2858 21.5377 16.4771 21.729C16.6684 21.9202 16.9324 22.0204 17.2024 22.0041V22.0041Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
