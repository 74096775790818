import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 11.5C20 6.805 16.195 3 11.5 3C6.805 3 3 6.805 3 11.5C3 16.195 6.805 20 11.5 20"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.27 19.27L21 21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2678 15.7322C20.2441 16.7085 20.2441 18.2914 19.2678 19.2677C18.2915 20.244 16.7086 20.244 15.7323 19.2677C14.756 18.2914 14.756 16.7085 15.7323 15.7322C16.7086 14.7559 18.2915 14.7559 19.2678 15.7322"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.887 15.281H3.945C6.778 15.281 8.667 15.119 8.667 12.448C8.667 9.615 11.5 9.615 11.5 7.726C11.5 5.614 7.722 5.837 7.722 3.948V3.88"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11.5H16.932C14.827 11.5 13.71 9.013 15.108 7.439L17.139 5.153"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
