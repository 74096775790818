import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 15.1899H9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.54106 14.8959C6.76506 13.6409 5.70006 11.4499 6.07506 9.03795C6.48106 6.43095 8.65406 4.34995 11.2751 4.04295C14.9131 3.61595 18.0001 6.44795 18.0001 9.99995C18.0001 12.0249 16.9941 13.8119 15.4571 14.8979C15.1811 15.0929 15.0001 15.3949 15.0001 15.7329V18.4999C15.0001 19.8809 13.8811 20.9999 12.5001 20.9999H11.5001C10.1191 20.9999 9.00006 19.8809 9.00006 18.4999V15.7369C9.00006 15.3959 8.81806 15.0919 8.54106 14.8959Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 5.00006L21.19 3.81006"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.00006 15L2.81006 16.19"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00006 4.00006L3.81006 2.81006"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 15L21.19 16.19"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 10H22.69"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.31006 10H3.00006"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 18H14.87"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
