import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 14H19.4319C19.8478 14 20.2467 13.8348 20.5407 13.5407C20.8348 13.2467 21 12.8478 21 12.4319V4.56918C21.0002 4.15312 20.8351 3.75401 20.541 3.45971C20.2469 3.16541 19.8479 3.00004 19.4319 3H12.5692C12.1531 2.99976 11.754 3.16486 11.4597 3.45896C11.1654 3.75306 11 4.15206 11 4.56812V10"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6291 11H6.47079C6.80185 11 7.11145 11.1638 7.29764 11.4376L8.21159 12.7812C8.30468 12.9181 8.45948 13 8.625 13H12.3709C13.2706 13 14 13.7294 14 14.6291V19.3709C14 20.2706 13.2706 21 12.3709 21H4.6291C3.72937 21 3 20.2706 3 19.3709V12.6291C3 11.7294 3.72937 11 4.6291 11Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 7H17.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 10H17.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
