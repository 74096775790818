import React from 'react'
import WithDivider from '../../WithDivider'
import Icon from '../../../icons/Icon'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'

export default function DetailsBar() {
  const { detailsScrollOn, winSize } = useAppContext()
  
  return (
    <div className={`flex  items-center bar-h justify-between w-full ${detailsScrollOn ? 'border-b border-main' : 'border-b border-transparent'}`}>
        
        <WithDivider>
            <>Details</>
        </WithDivider>
        {winSize.w > maxLgWinWidth && (
            <div className="flex items-center space-x-2">
                <button className='bg-hover-transparent p-1 rounded disabled:text-skin-muted' disabled>
                    <Icon name='Fullscreen' className='icon-sm' />
                </button>
                <button className='bg-hover-transparent p-1 rounded disabled:text-skin-muted' disabled>
                    <Icon name='Print' className='icon-sm' />
                </button>
            </div>

        )}

    </div>
  )
}
