import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 10.5803L10.6984 3.9815C11.4474 3.3395 12.5526 3.3395 13.3016 3.9815L21 10.5803"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 21H3"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6236 5.97182V3.75C15.6236 3.33579 15.9594 3 16.3736 3H18.9645C19.3787 3 19.7145 3.33579 19.7145 3.75V9.47839"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.28551 9.47839V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6671 13H14.188C13.5319 13 13 13.532 13 14.1881V16.6666C13.0004 16.982 13.1261 17.2843 13.3494 17.507L16.4941 20.652C16.9583 21.1159 17.7106 21.1159 18.1748 20.652L20.6521 18.1753C21.116 17.711 21.116 16.9587 20.6521 16.4945L17.5074 13.3495C17.2847 13.1261 16.9824 13.0004 16.6671 13V13Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5323 15.5322C15.515 15.5496 15.515 15.5777 15.5324 15.595C15.5497 15.6124 15.5779 15.6124 15.5952 15.595C15.6126 15.5777 15.6126 15.5495 15.5952 15.5322C15.5779 15.5148 15.5498 15.5148 15.5324 15.5321L15.5323 15.5322"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
