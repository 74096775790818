import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0029 5.44727H15.0303C14.6991 5.44745 14.3893 5.28376 14.2029 5.01009L13.1315 3.43343C12.9442 3.15859 12.6327 2.99474 12.3001 2.99624H8.99875C7.89372 2.99624 6.99792 3.89206 6.99792 4.99708V14.0008C6.99792 15.1059 7.89372 16.0017 8.99875 16.0017H19.0029C20.1079 16.0017 21.0038 15.1059 21.0038 14.0008V7.44811C21.0038 6.34308 20.1079 5.44727 19.0029 5.44727Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 6.99792H4.99709C3.89206 6.99792 2.99625 7.89372 2.99625 8.99875V19.0029C2.99625 20.1079 3.89206 21.0038 4.99709 21.0038H16.752C17.857 21.0038 18.7528 20.1079 18.7528 19.0029V16.0017"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
