import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'
import { useTranslation } from 'react-i18next'



export default function HomeLayout() {
  const { t }:any = useTranslation(['common'])
  return (
    <ResourcesLocationLayout data={[
      {
        title: 'Home',
        key: '/home',
        blocks: [
          {
            key: '/',
            name: t('resources').home?.label,
            icon: '',
            description:t('resources').home?.title,
          },
        ]
       },
       {
        title: 'Home',
        key: '/home',
        blocks: [
            {
                key: '/home/platform',
                name: t('resources').platform?.label,
                icon: '',
                description: t('resources').platform?.title
            },
           
            {
                key: '/home/solution',
                name: t('resources').solutions?.label,
                icon: '',
                description: t('resources').solutions?.title,
            },
            {
                key: '/home/how-it-works',
                name: t('resources').howItWorks?.label,
                icon: '',
                description:t('resources').howItWorks?.title,
            },
            {
              key: '/home/avantages',
              name: t('resources').avantages?.label,
              icon: '',
              description: t('resources').avantages?.title,
          },
          {
            key: '/home/pricing',
            name: t('resources').pricing?.label,
            icon: '',
            description: t('resources').pricing?.title,
            disabled:true
        }
          ,
          {
              key: '/home/joined',
              name: t('resources').join?.label,
              icon: '',
              description: t('resources').join?.title,
              disabled:true
          }
          ,
          {
              key: '/home/demo',
              name:t('resources').demo?.label,
              icon: 'Demo',
              description: t('resources').demo?.title,
              disabled:true
          }
        ]
    },
    {
      title: 'Home',
      key: '/home',
      blocks: [
          {
              key: '/home/about',
              name: t('resources').about?.label,
              icon: '',
              description: t('resources').about?.title,
              disabled:true
          },
          {
              key: '/home/hiring',
              name: t('resources').jobs?.label,
              icon: '',
              description: t('resources').jobs?.title,
              disabled:true
          },
          {
            key: '/home/contact',
            name: t('resources').contact?.label,
            icon: '',
            description: t('resources').contact?.title,
            disabled:true
        },
        
        
      ]
  },
    ]}>
      <></>
    </ResourcesLocationLayout>
  )
}
