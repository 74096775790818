import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3857 7.99854H19.4471C20.3075 7.99854 21.0038 8.69483 21.0038 9.55518V19.4483C21.0038 20.3077 20.3075 21.004 19.4471 21.004H8.55457C7.69421 21.004 6.99792 20.3077 6.99792 19.4473V18.0027"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9976 18.0029H15.3143C15.9836 18.0029 16.6079 17.6688 16.979 17.1115L17.7133 16.0091C18.1515 15.3518 18.3856 14.5795 18.3856 13.7891V5.9979C18.3856 4.89244 17.4902 3.99707 16.3848 3.99707H6.3806C5.27514 3.99707 4.37976 4.89244 4.37976 5.9979V13.0568C4.37976 13.6781 4.2347 14.2904 3.95759 14.8466L3.10323 16.5553C2.77009 17.2206 3.25429 18.0029 3.9976 18.0029Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6517 9.59229L10.8375 12.4065L9.1488 10.7188"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.3785 2.99658V4.99742"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3809 2.99658V4.99742"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
