import React from 'react'
import WithDivider from '../../WithDivider'
import DetailsBar from './DetailsBar'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'

export default function ResourcesBar() {
    const { winSize } = useAppContext()
  return (
    <div className="flex-1 flex overflow-hidden">
        <div className={`${winSize.w > maxLgWinWidth ? 'w-[30%] max-[1825px]:w-[400px] max-[1660px]:w-[300px]' : 'w-full'}`}>
        
            <WithDivider>
                <>Resources</>
            </WithDivider>

        </div>
        {winSize.w > maxLgWinWidth && (
            <div className='flex-1'>
                <DetailsBar/>
            </div>

        )}

    </div>
  )
}
